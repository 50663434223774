//
// STORE M.JS

import Vue from "vue";

const getDefaultState = () => {
  return {   
    storeTipo:'M',
    record: {},
    recordRead: {},
    perm: [],    
    permExtra: [],
    
    ID:0,
    accion:'',
    modal:false,
    // accionCt:{}, (solo storeF)
    disparo:false,
    estado: "indefinido"      
  };
};


export default {
  namespaced: true,
  state: () => {
    return {
      api: "",
      name: "",
      titulo: "",      
      recordAccess: "local",
      masterStore: "",
      relation: "",
      mView:"",
      pView:[],      
     
    
      sch: 0,    
      // eliminar?????
      no_getRecordAPI:0,     
          
      ...getDefaultState()
    };
  },


  mutations: {
    // inicializar variables del state
    INI(state, data) {
      Object.assign(state, data);      
      console.log("INI store", data);
    },

    // resetear variables del state
    RESET(state) {
      Object.assign(state, getDefaultState());
      console.log("RESET store");
    },

    // guardar schema, permisos y botonera en el store
    SCHSET(state, data) {      
      console.log('SCH ', state.name, ': ', data);
      Vue.set(state, "sch", data[0]);
    },

    //
    PESOSET(state, data) {      
      Vue.set(state, "perm", data.permisoMto);
      Vue.set(state, "permExtra", data.permisoAccion);
    },

    // actualizar propiedad del state
    data2State(state, data) {
      if (data.key) {
        Vue.set(state[data.prop], data.key, data.value);
        return;
      }

      Vue.set(state, data.prop, data.value);
    },

    // actualizar ID y record Mto
    recordSet(state, records) {
      // ID
      Vue.set(state, 'ID', records[0].id);

      // record Mto
      Vue.set(state, "record", JSON.parse(JSON.stringify(records[0])));
      Vue.set(state, "recordRead", JSON.parse(JSON.stringify(records)));
    },


    // actualizar variables del state al hacer un VER
    verUpdate(state) {
      Vue.set(state, "estado", "ver");     
      Vue.set(state, "change", false);     
    },


    // actualizar variables del state al hacer un EDITAR
    editarUpdate(state) {
      Vue.set(state, "estado", "editar");     
      Vue.set(state, "change", false);    
    },

    
    // actualizar variables del state al hacer un NUEVO
    nuevoUpdate(state) {      
      Vue.set(state, "estado", "nuevo");     
      Vue.set(state, "change", false);    
    },


    // actualizar variables del state al hacer un CANCELAR
    cancelarUpdate(state) {
      Vue.set(state, "estado", "indefinido");    
      Vue.set(state, "change", false);     
    },


    // actualizar variables del state al hacer un ELIMINAR
    eliminarUpdate(state) {      
      Vue.set(state, "estado", "indefinido");   
      Vue.set(state, "change", false);    
    },

    // actualizo variables de acciones Grid
    accionSet(state, { id, accion }) {   
      console.log('accionSet:: ', { id, accion });      
      Vue.set(state, "ID", id);
      Vue.set(state, "accion", accion);
    },

    disparoSet(state) {
      state.disparo= !state.disparo;
    },

    // 
    modalSet(state, val) {
      console.log('ModalSet a ', val);
      Vue.set(state, "modal", val);
    }

  },


  actions: {
    // cargar schema Mto
    async ini({dispatch, commit, rootGetters}, { stIni, schAPI, storeName }) {     
      commit("INI", stIni);

      // guardo permisos de mView
      let peso= rootGetters.pesoGet(stIni.mView || stIni.name);      
      commit("PESOSET", peso);
      
      // si en schAPI recibo null quiere decir que el mto particular va a utilizar
      // un schema controls propio
      if (!schAPI) return;
 
      await dispatch('schGet', { storeName:storeName, api:stIni.api, schAPI:schAPI }, {root:true});
    },

    
    // ver registro
    async ver(context, { param, origen }) {

      // param: { apiArgs, args } o tipo de lectura forzada (tambien: local, serv, masterStore....)
      // recordAcces:'masterStore',  // El record lo extrae del masterSyncRecord no hay que hacer lectura del servidor, guardar datos de record en state
      // recordAccess:'local',       // Al guardar devuelve los datos guardados y se hace un ver('local')
      // recordAccess:'serv',        // Al guardar no devuelve los datos guardados y hay que hacer un ver('serv')   
     
      let recordAccess= context.state.recordAccess;
      let apiResult='';
      let records= {};

      //
      switch (recordAccess) {
        case 'masterStore': 
          records= [context.rootGetters[context.state.masterStore + '/syncRecord']];
          break;
    
        case 'serv':              
        case 'local':
          if (origen==='guardar' && recordAccess==='local') {
            // pongo recordRead para que incluya los recordsAjuntos si los tiene
            records= context.state.recordRead;

          } else {                           
            apiResult = await context.dispatch("apiCall", param, { root:true });
            // Pendiente: controlar error API               
            records= apiResult.r;                
          }              
          break;
      }

      console.log("apiResult ver (storeM): ", records);
      
      // actualizo variables del state
      context.commit("recordSet", records);
      context.commit("verUpdate");      
      return records;
    },
    

    // editar registro
    async editar(context) {      
      // actualizo variables del state
      context.commit("editarUpdate");
    },


    // cancelar edición registro
    async cancelar(context) {
      // actualizo variables del state
      context.commit("cancelarUpdate");      
    },
   

    // nuevo registro
    nuevo(context, record) {
      console.info('record nuevo: ', record);
      // actualizo record Mto a array vacío      
      context.commit("recordSet", record);
            
      // actualizo variables del state
      context.commit("nuevoUpdate");     
    },


    // guardar registro
    async guardar(context, param) {
      // call API
      let apiResult = await context.dispatch("apiCall", param, {root:true}); 
      console.log("apiResult guardar M: ", apiResult);

      //
      if (apiResult.sql.error) return apiResult;

      // Guardo los datos de vuelta de la grabación
      if (context.state.recordAccess== 'local') {
        context.commit("recordSet", apiResult.r);          
      }

      return apiResult;
    },

    // guardar registro tipo formData
    // utilizamos esta action para hacer un guardar con imágenes
    async guardarFormData(context, formData) {
      // call directo a AjaxRequest
      let apiResult = await context.dispatch("ajaxRequest", { args: formData }, {root:true});        
      console.log("apiResult guardarFormData M: ", apiResult);
      // Pendiente: controlar error API

      return apiResult;     
    },


    // eliminar registro
    async eliminar(context, param) {
      // call API
      let apiResult = await context.dispatch("apiCall", param, {root:true});            
      console.log('apiResult eliminar M: ', apiResult);
      // Pendiente: controlar error API

      // actualizo variables del state
      context.commit('eliminarUpdate');    
      return apiResult;   
    },


  },


  getters: {
    // estado edicion
    noEdit: state => {      
      return state.estado == "ver" || state.estado== "indefinido";
    },

    // duplico por polimorfismo por llamada desde el baseGridMD 
    // porque en un expansible puedo utilizar un storeM o un storeMD
    // En el StoreM duplico la fn noEdit a noEditMX
    // En el StoreMD duplico la fn noEdit1 a noEditMX    
    noEditMX: state => {
      return state.estado == "ver" || state.estado== "indefinido";
    },

    
    // creo syncRecord para polimorfismo entre stores sobre el record activo o seleccionado 
    // en storeF tenemos el seleccionado en recordsSelected[0]
    // en storeM tenemos el activo en record
    // en storeMD tenemos el seleccionado en recordsSelected[0]
    // ...
    // normalizamos con el getter en synRecord    
    syncRecord: state => {
      if (!Object.keys(state.record).length) return {};
      return state.record;
    }
  }
};
