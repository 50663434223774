  // CARGA DE DATOS
// hay 2 tipos: sincronizado (sync=true)
//              a peticion (con disparo)

// SINCRONIZADO:
// propiedades afectadas:
// sync=true
// syncAccion: acción que ejecuta al sincronizar
// syncUpdate: (true/false) actualiza masterStore (cancelarNuevo, guardar, eliminar)
// masterStore (indica el store al que se sincroniza masterStrore.syncRecord)
// masterRecordField (campo del  masterStore.syncRecord  que utilizamos para sincronizar, por defecto id)
// IDAux:

// variables:
// accion. computed de syncAccion

// WACTHS
// watch masterField (masterField es un computed de masterStore.syncRecord[masterRecordField])
    // ejecuta watchAccion ('masterField');

// metodos
  // watchAccion(origen, accion):
      // ejecuta setStoreAccion() para guardar ID y acción en el Store
        // actualizo ID, accion
              // (this.$store.commit(this.storeName + '/accionSet', { id: this.masterField, accion:this.syncAccion })
              // this.$store.commit(this.storeName + '/modalSet', true); (abro ventana si es modal)

      // ejecuta watchAccionExec()
        // watchAccionExec()
          // ejecuta execAccion(computed accion):
            // execAccion(): ejecuta la acción


// Por peticion :
// propiedades afectadas:
// sync=false
// syncAccion: acción a ejecutar cuando se dispare el componente
// syncDisparo: switch que al cambiarlo ejecuta el disparo del componente
// syncUpdate: (true/false) actualiza masterStore (cancelarNuevo, guardar, eliminar)
// masterStore (indica el store master)
// masterRecordField (campo del  masterStore.syncRecord que utilizamos para obtener el id)


// WATCHS
  // watch syncDisparo.

  //  (a partir de aqui igual que el sincronizado)

//------------------------------------------------------------------------------------------------------------------------------


/*  TRATAMIENTO DE ACCIONES
     variables
    // storeName.ID
    // storeName.accion

// ej. accion M ( CICLO VER...)
// Metodos
      watchAccion (guardamos ID y acción en Store)
      execAccion(evento ver)
      ver()
      stVer() (STORE)
        apiCall (store.js)
        recordSet
        verUpdate
      showRecord(record)
        guardo controles en ct y le paso los datos del registro leido
        record2ctrl
        getDataAux() Carga datos auxiliares si es necesario para el particular
      verFin()
*/






import { mixCt } from "@/mixins/mixCt";
import { mixSchValidate } from "@/mixins/mixSchValidate";
import { mixBtras } from "@/mixins/mixBtras";
import storeM from "@/stores/modulos/storeM.js";

export var mixM = {
  mixins: [mixCt, mixSchValidate, mixBtras],
  props: {
    sync: { type: Boolean, default: false },              // vista activa del mto sincronizada con el masterField del masterStore
    syncAccion: { type: [String, Number], default: 0 },   // acción a ejecutar al sincronizar
    syncDisparo: { type: Boolean, default: false },         // switch que al cambiarlo ejecuta el disparo del componente
    syncUpdate: { type: Boolean, default: false },        // (true/false)). True: actualiza su masterStore
    storeRaiz: { type: String, default: "" },             // nombre del store del que cuelga el componente
    masterStore: { type: String, default: '' },           // this.id= this.$store.state[this.masterStore].record[this.masterRecordField] (store relación)
    masterRecordField: { type: String, default: 'id' },   // nombre de campo donde extraigo el id relacionado
    //
    storeAux: { type: String, default: "" },              // store auxiliar
    recordAux: { type: Object, default: null },           // record auxiliar
    IDAux: { type: [Number, String], default: '' },       // id auxiliar necesario para recopilar datos externos se definen en dataAux
    ctrlCt: { type: Array, default:()=> [] },             // array ct del control
    readOnly: { type:Boolean, default:false },            // obliga al mto como solo lectura (omite los permisos)
    viewMtoOnly: { type: Boolean, default:false }         // muestra mto sin mostrar el finder
  },

  data() {
    return {
      raiz: '',                                            // store raiz name para pasar
      storeName: '',                                       // store name particular (storeRaiz + stIni.api + (index si storeRaiz== '')
      ct: 0,                                               // array de controles
      apiArgs: {},                                         // objeto donde están definidas las llamadas a la API
      dataAux: {},                                         // variable con datos por defecto de recordDataAux
      recordDataAux:{},                                    // datos donde se almacena el getDataAux
      //
      btMtoCfg: null,                                      // (design, btnsAccion). Configuración botonera Mantenimiento
      btExtCfg: null,                                      // (design, btnsAccion). Configuración botonera Extra
      loaded: false,                                       // switch que indica si han terminado las cargas asíncronas para cargar el template
      componenteDinamico:null,                             // nombre del Mto dinámico

      syncDisparoSend:false,                              // switch que al cambiarlo ejecuta el disparo del componente asociado (sólo si se pasa como propiedad)
      syncAccionSend:''                                   // acción a ejecutar en el componente asociado (sólo si se le pasa como propiedad)
    }
  },


  async created() {
    console.log("DEV " + this.stIni.api + " ********************** (created)");

    // inicializo datos auxiliares generales (Mixin)
    this.iniDataMixin();

    // inicializo apiArgs
    this.iniDataApi();

    // inicializo variables Btra
    this.iniBtra();

    // registro store
    await this.registerStore(storeM);

    // inicializo store (stIni y get_schema)
    await this.iniStore();

    // paso schema a controles
    this.ctIni();

    // inicializo datos particulares
    await this.iniDataParticular();

    // funciones a ejecutar una vez cargado totalmente el componente
    this.loadedComponent();


    // watch's:
    if (this.sync) {

      this.$watch("masterField", {
        immediate: true,
        handler() {
          console.log('watch MASTERFIELD M');
          this.watchAccion('masterField');
        }
      });

    } else {

      this.$watch("syncDisparo", {
        handler() {
          console.log('watch SYNCDISPARO M');
          if (this.masterField== null) return;
          this.watchAccion('syncDisparo');
        }
      });
    }

  },


  methods: {

    // ini Data General
    iniDataMixin() {
      console.log("DEV " + this.stIni.api + " ********************** (iniDataMixin)");

      //
      this.apiArgs = {
        sch: ["mto", 'getSchema', {}, this.stIni.api],
        get: ["mto", 'ver', { id: 0 }, this.stIni.api],
        set: ["mto", 'guardar', { id: 0, estado: '', changes: {} }, this.stIni.api],
        del: ["mto", 'borrar', { id: 0 }, this.stIni.api]
      }
    },

    // override de apiArgs en particular en caso que sea necesario
    iniDataApi() { },

    // inicializo Btra Mto
    iniBtra() {
      this.btMtoCfg = JSON.parse(JSON.stringify(this.$cfe.btra.M));
      this.btExtCfg= JSON.parse(JSON.stringify(this.$cfe.btra.EXTRA));
    },

    // inicializo datos particulares
    iniDataParticular() { },


    // entro cuando el componente se ha cargado
    loadedComponent() {
      // indico que el componente ya se ha cargado
      // el template se carga porque tiene loaded como condicion
      this.loaded= true;

      // viewMtoOnly:true (normalmente viene de un ctrlF)
      // actualizo ID, con el recibido en el ctrlCt, la acción y ejecuto disparo
      if (this.viewMtoOnly) {
        this.$store.commit(this.storeName + '/accionSet', { id: this.ctrlCt[2], accion:0 });
        this.$store.commit(this.storeName + '/disparoSet');
        return;
      }

      // configuración particular botonera
      this.botonerasSet();

      // emito evento por loaded por si lo recoge alguna funcion en el padre
      // por si hay que realizar alguna operación después de la carga del componente
      this.$emit('loaded', this.storeName);

      // si el componente esta sincronizado, el watch del masterField tiene un inmediate:true que
      // lanzaría automáticamente el watchAccion. Cortarlo aquí evita que se vuelva a repetir al hacer la carga del componente
      if (this.sync) return;
      this.loadedAccion();
    },


    // habilito / deshabilito botones
    botonerasSet() {},


    //
    loadedAccion() {
      // funcion que se ejecuta cuando está cargado el componente NO sincronizado
      this.watchAccion('loaded');
    },


    // ---- Acciones Watch ---------------------------------------------------------
    watchAccion(origen, accion = this.syncAccion) {
      console.log("DEV ********** watchAccion (MIXM). Origen: ", origen, ', Accion: ', accion);

      //origen=='syncDisparo'
      //origen=='masterField'
      //origen=='loaded'
      this.setStoreAccion(accion);
      this.watchAccionExec();
    },


    // guardo ID y acción en storeM
    setStoreAccion(accion) {
      console.log("DEV ********** setStoreAccion (MIXM)");

      this.$store.commit(this.storeName + '/accionSet', { id: this.masterField, accion: accion });
    },


    // this.accion es un computed del store state.accion
    // ejecuto acción
    watchAccionExec() {
      console.log('DEV ********** watchAccionExec (MIXM): ', this.accion, this.ID);

      // si no se recibe una acción, no se ejecuta nada
      if (this.accion === "") return;

      // si la acción a ejecutar es un 'nuevo' y tiene ID, no se ejecuta nada
      //if (this.accion== 1 && this.ID> 0) return;

      // muestro ventana de Mto
      if (!this.sync) this.$store.commit(this.storeName + '/modalSet', true);

      // ejecuto acción
      this.execAccion({ accion: this.accion });
    },
    //-----------------------------------------------------------------------


    // call Acciones M ---------------------------------------------------------
    execAccion(evt) {
      console.log("DEV execAccion ********** MIXM", evt);

      // (0:'ver', 1:'nuevo', 2:'editar', 3:'eliminar', 4:'guardar', 5:'cancelar', 6:'salir')
      const options = [
        "ver",
        "nuevo",
        "editar",
        "eliminar",
        "guardar",
        "cancelar",
        "salir"
      ];
      let accion = /^([0-9])*$/.test(evt.accion)
        ? options[evt.accion]
        : evt.accion;

      if (this.$isFunction(this[accion])) this[accion](evt);
    },
    // ---------------------------------------------------------------------


    // acciones M ---------------------------------------------------------

    // muestro registro en modo NO edición
    async ver() {
      if (!this.isvalidID()) return;

      // call Store
      await this.stVer();
      // Pendiente: controlar error API

      //
      await this.showRecord();

      // gancho después de ver
      this.verFin();
    },

    verFin() {
      this.crudFin();
    },


    // gancho antes de editar
    editarBefore() { return true; },

    // muestro registro en modo edición
    async editar() {
      if (!this.isvalidID()) return;
      if (!this.editarBefore()) return;

      // call Store
      await this.stVer();
      // Pendiente: controlar error API

      // edito mto y paso record a controles
      await this.stEditar();
      await this.showRecord();

      // gancho después de 'editar''
      this.editarFin();
    },

    editarFin() {
      this.crudFin();
    },


    // gancho antes de guardar el record del schema en el store
    nuevoBefore() {},

    // nuevo registro
    async nuevo() {
      // inicializo schema y lo modifico si lo necesito en 'nuevoBefore'
      this.ctIni();

      await this.nuevoBefore();

      // call Store
      await this.stNuevo();
      await this.showRecord();

      // gancho después de nuevo
      this.nuevoFin();
    },

    nuevoFin() {
      this.crudFin();
    },


    async showRecord() {
      // inicializo ct
      this.ctIni();

      await this.record2ctrl(this.record, this.ct, this.estado == 'nuevo');

      // gancho por si necesitamos cargar datos auxiliares
      // entro DESPUÉS de obtener los datos del Mto
      await this.getDataAux();
    },


    // gancho por si necesitamos cargar datos auxiliares
    // entro DESPUÉS de obtener los datos del Mto
    getDataAux() {},


    // cancelo la edición del registro
    async cancelar() {
      // si hay cambios, muestro pregunta para cancelarlos
      // si NO hay cambios, cancelo el registro directamente
      if (this.changes() != '') {
        this.cancelarPregunta();

      } else {
        this.cancelarSi();
      }
    },

    async cancelarPregunta() {
      // muestro pregunta para confirmar la cancelación
      await await this.$root.$alert
        .open("¿ CANCELAR los cambios realizados ?", "confirm", null, [
          "NO",
          "SI"
        ])
        .then(r => {
          if (r) this.cancelarSi();
        });
    },

    cancelarSi() {
      // si estado es NUEVO, actualizo datos Store y cierro M
      if (this.estado == "nuevo") {
        // si syncUpdate== true cancelaNuevo de masterStore
        this.masterStoreUpdate('cancelarNuevo');
        this.salir();
        return;
      }

      // estado EDITAR. Actualizo datos Store y hago un VER
      this.stCancelar();
      this.execAccion({ accion: 0 });
      return;
    },


    // gancho para devolver el record a guardar (con oportuidad de incluir todo, solo los cambios o los nosave)
    // por defecto devolvemos sólo los campos modificados
    getRecordChangesToSave() {
      return this.ctrl2record(this.ct, this.record, true);
    },


    // gancho antes de guardar
    guardarBefore()  { return true; },

    // guardar registro
    async guardar() {
      if (!this.guardarBefore()) return;

      // validate mantenimientos auxiliares
      if (!this.validateAuxEstado()) return;

      // validate schema
      if (!this.validateErrors()) return;

      // valido datos particulares
      if (!this.validate()) return;

      // obtengo cambios (sólo cambios) y almaceno en recordChanges
      let recordChanges= await this.getRecordChangesToSave();
      console.log("recordChanges:", recordChanges);

      // si NO hay cambios y estamos en edición, hacemos un VER y salimos
      if (!Object.keys(recordChanges).length && this.estado == "editar") {
        this.execAccion({ accion: 0 });
        return;
      }


      // ------- CAMPO RELACIONADO
      // si estamos en un NUEVO, hemos definido un campo relacionado (stIni.relation - particular)
      // y tenemos definido un relationID (data/computed - particular), guardamos ese ID en el record
      if (this.estado == "nuevo" && this.stIni.relation && (this.relationID && this.relationID > 0)) {
        recordChanges[this.stIni.relation] = this.relationID;
      }

      await this.guardarBeforeAPI(recordChanges);
      await this.guardarAPI(recordChanges);
    },

    // gancho despues de obtener los records pero antes de guardar
    guardarBeforeAPI() {},

    //
    async guardarAPI(recordChanges) {

      // call Store
      let apiResult = await this.stGuardar(recordChanges);

      //
      if (apiResult.sql.error) {
        this.$root.$alert.open('Error en grabación', 'error');

        // error fatal en la grabación.
        // Muestra mensaje pero NO continua con la grabación
        //await this.guardarError(apiResult);
        return;

      }else {
        // warning en la grabación.
        // Muestra mensaje de advertencia pero continua con la grabación
        //await this.guardarWarning(apiResult);
      }

      await this.guardarFin();

      this.$root.$alert.open("Registro guardado correctamente!", "success", 1000);

      // actualizo record maestro, si corresponde, y mto
      await this.guardarUpdate();
    },


    //
    async guardarUpdate() {
      // si es un guardar nuevo, ejecuta la mutation 'nuevo' del master asociado,
      // normalmente será un finder, así que, añadirá una linea nueva con el ID del nuevo registro
      /* if (this.estado== 'nuevo') {
        this.masterStoreUpdate('nuevo', apiResult.r);
      } */

      // recargo Mto
      if (!this.masterStore || !this.$store.state[this.masterStore].expansibleMX) {
        await this.stVer('guardar');
        this.showRecord();
      }

      // actualizo record del master asociado si lo tuviera
      await this.masterStoreUpdate('refresh');

      // gancho después de guardar
      this.guardarUpdateFin();
    },

    async guardarFin() {
    },

    guardarUpdateFin() {
      this.crudFin();
    },

    async guardarError(apiResult) {
      if (apiResult.errors && apiResult.errors.fatal) {
        let msg= "Revise los siguientes errores:";
        msg+= `<br>${apiResult.errors.fatal}`;

        await this.$root.$alertP.open(msg, 'error');
        return;
      }

      this.$root.$alertP.open(apiResult.sql.msg || "Error en la grabación", 'error');
    },

    async guardarWarning(apiResult) {
      if (!apiResult.errors || !apiResult.errors.warning) return;

      let msg= "Revise las siguientes advertencias:";
      msg+= `<br>${apiResult.errors.warning}`;
      await this.$root.$alertP.open(msg, 'info');
    },


    // gancho antes de eliminar
    eliminarBefore() { return true; },

    // elimino registro cabecera y todas sus lineas
    async eliminar() {
      let res= this.eliminarBefore();
      if (!res) return;

      await await this.$root.$alert
        .open("¿ Desea eliminar el registro ?", "confirm", null, [
          "NO",
          "SI"
        ])
        .then(r => {
          if (r) this.eliminarSi();
        });
    },

    async eliminarSi() {
      // call Store
      let apiResult = await this.stEliminar();
      console.log('apiResult eliminar M: ', apiResult);
      // Pendiente: controlar error API

      // elimino registro del master asociado
      this.masterStoreUpdate('refresh');

      // ejecuto accion SALIR
      this.execAccion({ accion: 6 });

      // gancho después eliminar
      this.eliminarFin();
    },

    eliminarFin() {
      this.crudFin();
    },


    // cierro ventana MD
    salir() {
      // si NO hay cambios o el estado es VER, cierro la ventana
      let msg = (!this.noEdit && !this.estado=='nuevo')? this.changes() : '';

      //
      if (msg) {
        // muestro error obtenido al comprobar si hay cambios
        this.$root.$alert.open(msg, 'error');
        return;
      }

      this.stCancelar();

      // si NO tengo un storeRaiz significa que no estoy asociado o syncronizado
      if (this.storeRaiz == "") {
        this.$router.push({ path: '/' });
        this.$store.commit("set_activeMenu", []);
        return;
      }

      // emito evento de cierre al padre
      this.$emit('onEvent', { accion:6, origen:'Mto' + (this.viewMtoOnly? 'View' : '') });

      // si es un mantenimiento virtual salgo
      if (this.viewMtoOnly) return;

      // cierro mto desde storeM
      this.$store.commit(this.storeName + '/modalSet', false);
    },


    // gancho final para todas las operaciones CRUD
    crudFin() {},
  // ---------------------------------------------------------------------



    //
    changes() { return this.changesM() },


    //
    changesM() {
      // si estado es NUEVO, devuelvo ''
      /* if (this.estado== 'nuevo') {
        this.stCancelar();
        return '';
      } */

      let recordChanges = this.ctrl2record(this.ct, this.record, true);
      if (!Object.keys(recordChanges).length && !this.change1) return '';

      let msg = '';
      if (Object.keys(recordChanges).length || this.change1) msg += 'Antes de Salir GUARDE o CANCELE los cambios';
      return msg;
    },


    //
    validate() {
      if (!this.validateParticular()) return false;
      return true
    },

    // gancho para realizar validaciones particulares de datos antes de guardarlos
    validateParticular(){
      return true;
    },


    // validación de errores del schema
    // si hay errores no permite continuar la grabación
    validateErrors() {
      let errors= this.schValidate(this.ct);
      if (errors.length) {
        let txt= "Revise los siguientes errores:";
        errors.forEach(error => txt+= `<br>${error}`);
        this.$root.$alert.open(txt, 'error');
        return false;
      }

      return true;
    },


    // comprueba que no haya ningún mantenimiento auxiliar en edición
    validateAuxEstado() {
      let error= false;

      Object.entries(this.$store.state).forEach(([key, store]) => {
        if (!store || !Object.keys(store).length) return;
        if (!key.includes(this.storeName) || key== this.storeName) return;
        if (!store.storeTipo || store.storeTipo=='F') return;
        if (store.estado!='nuevo' && store.estado!='editar') return;

        this.$root.$alert.open('Existe un mto auxiliar en edición, GUARDE o CANCELE datos para continuar', 'info');
        error= true;
      });

      return !error;
    },


    //
    masterStoreUpdate(accionMasterSt, params = null) {
      if (!this.syncUpdate) return;
      this.$store.dispatch(this.masterStore + '/' + accionMasterSt, params);
    },


    // si es un virtualView (mto virtual) asigno el schema al ct sin clonar
    // si no, clono schema en ct
    ctIni() {
      if (this.ctrlCt.length
          && this.ctrlCt[4].comp
          && this.ctrlCt[4].comp.tipoView
          && this.ctrlCt[4].comp.tipoView=='FCA') {
            this.ct= this.sch;
            return;
      }

      console.log('sch: ', this.sch);
      this.ct = JSON.parse(JSON.stringify(this.sch));
    },


    // ---- Acciones Store ---------------------------------------------------------
    async stVer(origen= '') {
      // apiArg: llamada API definida en el particular.
      // args: argumentos a fusionar con los definidos en 'args' de apiArg.get
      let param = { apiArg: this.apiArgs.get, args: { id: this.ID } };
      console.log("stVer *************************:", param, origen);
      return await this.$store.dispatch(this.storeName + "/ver", { param, origen });
    },

    async stEditar() {
      await this.$store.dispatch(this.storeName + "/editar");
    },

    async stCancelar() {
      return await this.$store.dispatch(this.storeName + "/cancelar");
    },

    async stNuevo() {
      return await this.$store.dispatch(this.storeName + "/nuevo", [this.ctrl2record(this.ct, {}, false, false)]);
    },

    async stEliminar() {
      // apiArg: llamada API definida en el particular.
      // args: argumentos a fusionar con los definidos en 'args' de apiArgs.set
      let param = { apiArg: this.apiArgs.del, args: { id: this.ID } };
      console.log("stEliminar *************************:", param);
      return await this.$store.dispatch(this.storeName + "/eliminar", param);
    },

    async stGuardar(record) {
      // apiArg: llamada API definida en el particular.
      // args: argumentos a fusionar con los definidos en 'args' de apiArgs.set
      let param = { apiArg: this.apiArgs.set, args: { id: this.ID, estado: this.estado, changes: record } };
      console.log("stGuardar *************************:", param);
      return await this.$store.dispatch(this.storeName + "/guardar", param);
    },

    async stGuardarFormData(record) {

      // apiArg: llamada API definida en el particular.
      // args: argumentos a fusionar con los definidos en 'fn_args' de apiArgs.set
      // devuelvo composición apiCall sin hacer la llamada a la API
      let args= await this.$store.dispatch("apiCallComposicion", { apiArg: this.apiArgs.set, args: record });
      args.args= JSON.stringify(args.args);

      // paso los argumentos a FORMDATA
      var formData = new FormData();
      for (const key in args) {
        formData.append(key, args[key]);
      }

      // añado el/los documento/s a formData
      if (this.files) formData.append("documentos", this.files);

      // añado token
      formData.append("token", sessionStorage.getItem("login") || null);

      // ejecuto action storeM
      return await this.$store.dispatch(this.storeName + "/guardarFormData", formData);
    },


    // compruebo si el ID es válido
    isvalidID() {
      if (!this.recordAccess) return false;
      if (this.ID== null || (typeof this.ID=== 'number' && Number(this.ID)<= 0)) {
        this.noID();
        return false;
      }

      return true;
    },


    //
    noID() {
      this.$root.$alert.open('Registro inválido, salga y recargue la página', 'error');
    },

    // ----------------------------------------------------------------------


    // eventos de la cabecera
    eventHeader(evt) {
      console.log("DEV eventHeader ********** MIXM", evt);

      if (this.storeRaiz == "") {
        this.$router.push({ path: '/' });
        this.$store.commit("set_activeMenu", []);
        return;
      }

      this.salir();
    },


    // destruyo el Store al destruir el componente
    // cuando se trata de un MX, sobreescribir esta función en el particular para evitar que
    // destruya el Store cada vez que abre el expansible.
    // Asegurarse destruirlo en su componente asociado
    destroyStore() {
      if (typeof this.$store.state[this.storeName]=== 'undefined') return;
      this.$store.unregisterModule(this.storeName);
    },

  },

  computed: {

    // devuelvo el valor del campo masterRecordField del record sincronizado
    masterField() {
      if (!this.masterStore) return null;
      if (!this.masterSyncRecord) return null;
      if (typeof this.masterSyncRecord[this.masterRecordField] === 'undefined') return 0;
      return this.masterSyncRecord[this.masterRecordField];
    },

    // id Mto
    ID() {
      if (!this.$store.state[this.storeName]) return;
      return this.$store.state[this.storeName].ID;
    },

    // (true/false). Mto modal visible o no (ahora mismo se utiliza a traves del componente dualTemplate)
    modal() {
      if (!this.$store.state[this.storeName]) return false;
      return this.$store.state[this.storeName].modal;
    },

    // acción a ejecutar
    accion() {
      if (!this.$store.state[this.storeName]) return;
      return this.$store.state[this.storeName].accion;
    },

    // (true/false). Devuelve si el Mto está en edición
    noEdit() {
      return this.$store.getters[this.storeName + "/noEdit"];
    },

    // estado de acciones ene l que se encuentra el Mto (ver, editar, nuevo, ...)
    estado() {
      return this.$store.state[this.storeName].estado;
    },

    // permisos de Mto
    permMto() {
      return this.$store.state[this.storeName].perm;
    },

    // permisos Extra
    permExtra() {
      return this.$store.state[this.storeName].permExtra;
    },

    // schema de controles
    sch() {
      //if (this.apiArgs.sch == null) return this.schCtrls;
      return this.$store.state[this.storeName].sch;
    },

    // objeto con los campos del Mto
    record() {
      return this.$store.state[this.storeName].record;
    },

    // tipo de lectura forzada (local, serv, masterStore....)
    recordAccess() {
      return this.$store.state[this.storeName].recordAccess;
    },

    // array de objetos con todos los elementos recibidos
    recordRead() {
      return this.$store.state[this.storeName].recordRead;
    },

    disparoSt() {
      if (!this.$store.state[this.storeName]) return false;
      return this.$store.state[this.storeName].disparo;
    },

    // records seleccionados del componente maestro asociado
    masterSyncRecord() {
      return this.$store.getters[this.masterStore + "/syncRecord"];
    },

    syncRecord() {
      return this.$store.getters[this.storeName + "/syncRecord"];
    },

  },


  watch: {

    // Para utilizar este disparo y ejecutar una accion, debemos asignar  el ID , accion y disparo  del store externamente
    disparoSt() {
      this.watchAccionExec();
    },

  },


  // destruyo store
  beforeDestroy() {
    this.destroyStore();
  },

};
